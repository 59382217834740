
import { defineComponent, ref, watch } from "vue";
import BaseForm from "../../components/base/form/BaseForm.vue";
import * as Yup from "yup";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import { Field } from "vee-validate";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import InsuredIService from "@/core/services/InsuredIService";
import { INSURED_TYPE } from "@/core/config/Constant";

export default defineComponent({
  name: "InsuredForm",
  components: {DateTimeSelect, BaseRadio, FormErrorMessage, BaseForm, Field},
  props: {
    disabled: {type: Boolean, default: false},
    insuredId: {type: String},
    insuredProps: {type: Object}
  },
  emits: ['insuredSaved'],
  setup(props, {emit}) {
    const model = ref(props.insuredProps)
    const submitting = ref(false)
    const validate = Yup.object().shape({
      firstName: Yup.string().required().label("Firstname"),
      lastName: Yup.string().required().label("Lastname"),
    });
    watch(() => props.insuredProps, (cb) => {
      model.value = cb
    })

    const formSubmit = () => {
      submitting.value = true
      if (props.insuredId) {
        InsuredIService.update(props.insuredId, model.value).then(res => {
          emit('insuredSaved', res)
        }).finally(() => {
          submitting.value = false;
        })
      } else {
        InsuredIService.create(model.value).then(res => {
          emit('insuredSaved', res)
        }).finally(() => {
          submitting.value = false;
        })
      }
    }
    return {
      model,
      validate,
      submitting,
      formSubmit,
      INSURED_TYPE,
    }
  }
})
